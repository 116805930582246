
<template>
  <div class="page">
    <van-tabs v-model="active">
      <van-tab title="风控">
        <feng-kong :strategy_id="strategy_id" v-if="active === 0 && strategy_id"></feng-kong>
      </van-tab>
      <van-tab title="策略">
        <strategy  :strategy_id="strategy_id" v-if="active === 1 && strategy_id"></strategy>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import FengKong from "./com/feng-kong";
import Strategy from "./com/strategy";
export default {
  name: "PostionSet",
  components: {
    FengKong,
    Strategy,
  },
  data() {
    return {
      active: 0,
      strategy_id: ''
    }
  },
  mounted() {
    this.strategy_id = this.$route.query.strategy_id
  }
};
</script>
