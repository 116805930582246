<style lang="less" scoped>
.fengkong {
  background-color: #333333;
  padding: 10px;
  margin: 10px;

  .title {
    margin: 10px 0;
  }
}
</style>

<template>
  <div class="fengkong">
    <div class="title">
      {{ form.title }}
    </div>
    <van-form label-width="160px">
      <!-- 通过 pattern 进行正则校验 -->

      <van-field name="switch" label="自动平衡仓位">
        <template #input>
          <van-switch v-model="form.enable_zd" size="20"/>
        </template>
      </van-field>
      <van-field v-if="!form.enable_zd"
                 label="固定仓位资金:"
                 v-model="form.gdcwzj"
                 name="pattern"
                 placeholder=""
                 :rules="[{ required: true, message: '请填写' }]"
      />
      <van-field
          label="杠杆倍数:"
          v-model="form.gg_times"

          name="pattern"
          placeholder=""
          :rules="[{ required: true, message: '请填写' }]"
      />

      <van-field
          readonly
          clickable
          name="picker"
          :value="show_form.strategy_value"
          label="策略值"
          placeholder="点击选择"
          @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
      </van-popup>

      <van-field
          label="策略参数:"
          v-model="form.strategy_params"
          name="pattern"
          placeholder=""
          :rules="[{ required: true, message: '请填写 使用逗号分割' }]"
      />

      <div style="margin: 16px">
        <van-button @click="handlerSubmit" round block type="info" native-type="submit"
        >提交
        </van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>

import {getStrategyFunctionList, getStrategyInfo, updateStrategy} from "../../../api/strategy_api";
import {Notify} from "vant";

export default {
  name: "Strategy",
  props: {
    strategy_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showPicker: false,
      form: {
        title: "OK-LUNAUSDT-SWAP-SVS-1M",
        enable_zd: false,
        gdcwzj: 0.2,
        gg_times: 0.2,
        strategy_value: 0.2,
        strategy_params: 0.2,
      },
      show_form: {
        strategy_value: ''
      },
      loading: false,
      columns: [],
      funs: [],
    };
  },
  mounted() {
    this.handlerGetStrategy().then(() => {
      this.handlerGetFun()
    })

  },
  methods: {
    onConfirm(e, index) {
      this.show_form.strategy_value = this.funs[index].strategy_function;
      this.form.strategy_value = this.funs[index].strategy_function_id;
      this.showPicker = false
    },

    handlerGetFun() {
      getStrategyFunctionList().then(res => {
        this.funs = res.data;
        this.columns = res.data.map(item => item.strategy_function)
        let item = res.data.find(item => item._id === this.form.strategy_value)
        if (item) {
          this.show_form.strategy_value = item.strategy_function;
        }
      })
    },
    //获取策略信息
    handlerGetStrategy() {
      return getStrategyInfo(this.strategy_id).then(res => {
        this.form = {
          title: res.data.strategy_name,
          enable_zd: res.data.is_rebalance,
          gdcwzj: res.data.pos_equity,
          gg_times: res.data.strategy_leverage.replace('x', ''),
          strategy_value: res.data.strategy_function_id,
          strategy_params: res.data.strategy_parameter
        }
      })
    },
    // 提交
    handlerSubmit() {
      let info = {
        is_rebalance: this.form.enable_zd,
        pos_equity: String(this.form.gdcwzj),
        strategy_leverage: this.form.gg_times ? 'x' + this.form.gg_times : '',
        strategy_function_id: this.form.strategy_value,
        strategy_parameter: this.form.strategy_params.split(',').map(item => Number(item)),
        strategy_id: this.strategy_id
      }
      if (this.loading) {
        return
      }
      if (info.is_rebalance) {
        delete info.pos_equity
      }
      updateStrategy({
        ...info
      }).then(res => {
        Notify({type: 'success', message: res.message})
        this.loading = false
      }).catch(err => {
        Notify({type: 'danger', message: err.message})
        this.loading = false
      })

    }

  }
};
</script>
