import request from '../utils/request'

//获取风控
export function getStrategyRisk(id=''){
    return request({
        method: 'get',
        url: '/api/v1/riskctrl',
        params: {
            strategy_id: id
        }
    })
}
//更新风控
export function updateStrategyRisk(form){
    return request({
        method: 'put',
        url: '/api/v1/riskctrl',
        data:{
            ...form
        }
    })
}

//创建风控
export function createRiskCtr(data) {
    return request({
        url: '/api/v1/riskctrl',
        method: 'post',
        data
    })
}

//获取风控下拉选项
export function getStrategyFunctionName(params) {
    return request({
        url: '/api/v1/config/strategy',
        method: 'get',
        params
    })
}

export function getStrategyInfo(id=''){
    return request({
        method: 'get',
        url: '/api/v1/strategy',
        params: {
            strategy_id: id
        }
    })
}
export function updateStrategy(form){
    return request({
        method: 'put',
        url: '/api/v1/strategy',
        data:{
            ...form
        }
    })
}

export function getStrategyFunctionList()
{
    return request({
        method: 'get',
        url: '/api/v1/config/strategy'
    })
}







