<style lang="less" scoped>
.fengkong {
  background-color: #333333;
  padding: 10px;
  margin: 10px;

  .title {
    margin: 10px 0;
  }
}
.van-radio__label{
  color: #dddddd !important;
}
</style>

<template>
  <div class="fengkong">
    <div class="title">
      {{ form.title }}
    </div>
    <van-form label-width="160px">
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
          is-link
          readonly
          name="picker"
          label="风控类型:"
          :value="this.risk_type"
          @click="showPopup()"
      />
      <van-popup v-model="visible" position="bottom">
        <van-picker
            ref="cellTreePicker"
            show-toolbar
            title="请选择风控类型"
            :defaultIndex="defaultIndex"
            :columns="this.columns"
            @confirm="choosePicker"
            @cancel="closePicker"
            value-key="label"
        />
      </van-popup>
      <van-field name="switch" label="立即生效：">
        <template #input>
          <van-switch v-model="form.immediately" size="20"/>
        </template>
      </van-field>
      <van-field
          label="档位数量:"
          v-model="form.gear_num"
          type="number"
          name="pattern"
          placeholder=""
          :rules="[{ required: true, message: '请填写' }]"
          v-if="risk_type === '多档止盈'"
      />
      <van-field
          label="平仓比例:"
          v-model="form.gear_rate_list"
          name="pattern"
          placeholder=""
          :rules="[{ required: true, message: '请填写' }]"
          v-if="risk_type === '多档止盈'"
      />

      <p style="margin-left: 5%;margin-top: 30px">设置触发条件：</p>

      <van-field
          label="止损比:"
          v-model="form.loss_rate"
          type="number"
          name="pattern"
          placeholder=""
          :rules="[{ required: true, message: '请填写' }]"
      />
      <van-field
          label="止盈比:"
          v-model="form.profit_rate"
          type="number"
          name="pattern"
          placeholder=""
          :rules="[{ required: true, message: '请填写' }]"
          v-if="risk_type !== '静态止盈止损'"
      />

      <template v-if="risk_type !== '静态止盈止损'">
        <van-field
            label="盈利涨幅:"
            v-model="form.range_of_profit_rate"
            type="number"
            name="pattern"
            placeholder=""
            :rules="[{ required: true, message: '请填写' }]"
        />
        <van-field
            label="止损价涨幅:"
            v-model="form.range_of_trigger_price"
            type="number"
            name="pattern"
            placeholder=""
            :rules="[{ required: true, message: '请填写' }]"
        />
        <template v-if="risk_type === '移动止盈'">
          <van-field name="switch" label="动态操作开关：">
            <template #input>
              <van-switch v-model="form.is_dynamic_operate" size="20"/>
            </template>
          </van-field>
          <template v-if="form.is_dynamic_operate">
            <van-field name="radio" label="自定义减仓比例：" >
              <br/>
              <template #input>
                <van-radio-group  v-model="form.customer_dumps_positions" >
                  <van-radio name="0" @click = 'getRadio(0)' style="color: #dddddd !important;"><span style="color: #dddddd !important;">同比例减仓</span></van-radio>
                  <br/>
                  <van-radio name="1" @click = 'getRadio(1)' style="color: #dddddd"><span style="color: #dddddd !important;">自定义减仓比例</span>
                    <!--                  <van-field v-if="form.radio == '自定义减仓比例'" v-model="form.customer_dumps_positions" label="文本"  style="width: 10px;height: 10px;background-color:pink;"/>-->
                  </van-radio>

                </van-radio-group>

              </template>
            </van-field>
            <van-field
                v-if="form.radio == '自定义减仓比例'"
                label="自定义减仓比例:"
                v-model="form.customer_dumps_positions"
                type="number"
                name="pattern"
                placeholder=""
                :rules="[{ required: true, message: '请填写' }]"
            />
          </template>

        </template>
      </template>
      <div style="margin: 16px">
        <van-button :loading="loading" @click="handlerSubmit" round block type="info" native-type="submit"
        >提交
        </van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  getStrategyInfo,
  getStrategyRisk,
  updateStrategyRisk,
  getStrategyFunctionName,
  createRiskCtr
} from "../../../api/strategy_api";
import {Notify} from "vant";

export default {
  name: "FengKong",
  props: {
    strategy_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultIndex: 0,
      tmp_label: "",
      tmp_index: 0,
      visible: false,
      risk_type: "请选择风控类型",
      is_create: false,
      form: {
        title: "",
        risk_type_info: {
          label: "",
          value: {
            strategy_function: "",
            _id: ""
          }
        },
        gear_num: '',
        gear_rate_list: '',
        immediately: false,
        loss_rate: '',
        profit_rate: '',
        range_of_profit_rate: '',
        range_of_trigger_price: '',
        is_dynamic_operate: false,
        radio: '同比例减仓',
        customer_dumps_positions: '',
        is_entrust: false,

      },
      columns: [],
      loading: false,
    };
  },
  mounted() {
    this.handlerGetStrategy()
  },
  methods: {
    //获取风控信息
    handlerGetStrategy() {
      getStrategyRisk(this.strategy_id).then(res => {
        // console.log(res)
        if (res.code === 0) {
          if (res.data._id) {
            this.is_create = false
            const info = res.data;
            this.form = {
              title: info.strategy_name,
              gear_num: info.gear_num,
              gear_rate_list: info.gear_rate_list,
              loss_rate: info.loss_rate,
              profit_rate: info.profit_rate,
              range_of_profit_rate: info.range_of_profit_rate,
              range_of_trigger_price: info.range_of_trigger_price,
              is_dynamic_operate: info.is_dynamic_operate,
              radio: '同比例减仓',
              customer_dumps_positions: info.customer_dumps_positions,
              immediately: false
            }
            let label = ""
            if (info.risk_strategy_name === "static_profit_and_loss") {

              label = "静态止盈止损"
            } else if (info.risk_strategy_name === "dynamic_profit") {
              label = "移动止盈"
            } else {
              label = "多档止盈"
            }
            this.form.risk_type_info = {
              label: label,
              value: {
                strategy_function: info.risk_strategy_name,
                _id: info.risk_strategy_id
              }
            }
            this.tmp_label = label
            this.risk_type = label
          } else {
            this.is_create = true
          }

        }
        this.getSelect()
      })

      getStrategyInfo(this.strategy_id).then(res => {
        this.form.title = res.data.strategy_name
      })
    },
    //提交风控
    handlerSubmit() {
      // console.log(this.form)
      if (this.form.risk_type_info !== "") {
        this.form._id = this.form.risk_type_info.value._id
        this.form.strategy_function = this.form.risk_type_info.value.strategy_function
        // this.$delete(this.detail, 'risk_type_info')
      }
      this.loading = true
      let obj = {}
      obj.is_entrust = this.form.is_entrust
      obj.loss_rate = Number(this.form.loss_rate)
      obj.strategy_id = this.strategy_id
      obj.risk_strategy_id = this.form._id
      obj.risk_strategy_name = this.form.strategy_function
      if (this.form.risk_type_info.label === "移动止盈") {
        if (this.form.radio === "同比例减仓") {
          obj.same_proportion_flag = true
          obj.customer_dumps_positions = 0
        } else {
          obj.same_proportion_flag = false
          obj.customer_dumps_positions = Number(this.form.customer_dumps_positions)
        }
        obj.range_of_trigger_price = Number(this.form.range_of_trigger_price)
        obj.profit_rate = Number(this.form.profit_rate)
        obj.range_of_profit_rate = Number(this.form.range_of_profit_rate)
        obj.is_dynamic_operate = this.form.is_dynamic_operate
      } else if (this.form.risk_type_info.label === "多档止盈") {
        obj.range_of_trigger_price = Number(this.form.range_of_trigger_price)
        obj.range_of_profit_rate = Number(this.form.range_of_profit_rate)
        obj.profit_rate = Number(this.form.profit_rate)
        obj.gear_num = Number(this.form.gear_num)
        obj.gear_rate_list = this.form.gear_rate_list.split(",").map(Number)

      }
      if (this.is_create) {
        createRiskCtr(obj).then(res => {
          Notify({type: 'success', message: res.message})
          this.loading = false
          this.$emit('onFkSuccess')
        })
      } else {
        this.$delete(obj, 'is_entrust')
        // if (this.state === "启动中") {
        //   this.dialogVisible = true
        // }
        obj.immediately = this.form.immediately
        // debugger
        updateStrategyRisk(obj).then(res => {
          Notify({type: 'success', message: res.message})
          this.loading = false
          this.$emit('onFkSuccess')
        })
      }
    },
    //获取风控下拉选项
    getSelect() {
      getStrategyFunctionName({strategy_type: '风控策略'}).then(res => {
        let data = JSON.parse(JSON.stringify(res.data))
        this.columns = data.map(item => {
          return {
            value: {
              _id: item._id,
              strategy_function: item.strategy_function
            },
            label: item.alias
          }
        })
        if (this.tmp_label.length > 0) {
          for (let i in this.columns) {
            if (this.columns[i].label == this.tmp_label) {
              this.tmp_index = i
            }
          }
        }
      })
    },
    async choosePicker() {
      let data = this.$refs.cellTreePicker.getValues();
      this.form.risk_type_info = data[0]
      this.risk_type = data[0].label
      this.$forceUpdate();
      this.visible = false;
    },
    async closePicker() {
      this.visible = false
    },
    showPopup() {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.cellTreePicker.setColumnIndex(0, this.tmp_index);
        this.$forceUpdate();
      });
    },
    getRadio(num){
      if(num === 0){
        this.form.radio = '同比例减仓'
      }else {
        this.form.radio = '自定义减仓比例'
      }
    }

  }
}
</script>
